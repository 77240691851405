import React, { useState, useRef } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Select,
  Input,
  Button,
  Field,
  Control,
  Columns,
  Column,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Cube Calculator",
    link: "/cube-volume-and-area-calculator/",
  },
]
const seeAlsoArray = [
  "/capsule-volume-and-area-calculator",
  "/cone-volume-and-area-calculator",
  "/conical-frustum-volume-and-area-calculator",
  "/cylinder-volume-and-area-calculator",
  "/ellipsoid-volume-and-area-calculator",
  "/percentage-calculator",
  "/rectangular-volume-and-area-calculator",
  "/sphere-volume-and-area-calculator",
  "/square-pyramid-volume-and-area-calculator",
]

function CubeCalculatorVolumeArea(props) {
  let [result, setresult] = useState("")
  let Length = useRef("")
  let unitSelector = useRef("")

  function calcVolume(e) {
    let LengthValue = Length.current.value
    let unit = unitSelector.current.value

    if (LengthValue === "" || isNaN(LengthValue)) {
      setresult(<strong>Please Enter Length Value</strong>)
    } else {
      LengthValue = Number(LengthValue)

      let volume = Math.pow(LengthValue, 3)

      setresult(
        <span>
          Volume is : {volume.toFixed(2) + unit}
          <sup>3</sup>
        </span>
      )
    }
  }
  function calcArea(e) {
    let LengthValue = Length.current.value
    let unit = unitSelector.current.value

    if (LengthValue === "" || isNaN(LengthValue)) {
      setresult(<strong>Please Enter Length Value</strong>)
    } else {
      LengthValue = Number(LengthValue)

      let Area = 6 * Math.pow(LengthValue, 2)

      setresult(
        <span>
          Surface Area is : {Area.toFixed(2) + unit}
          <sup>2</sup>
        </span>
      )
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Cube Volume and Area Calculator"
        description="Calculate volume and area of Cube, Cube surface area, cube lateral surface, cube base surface, cube volume."
        keywords={[
          "volume and area of Cube, Cube surface area, cube lateral surface, cube base surface, cube volume.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Cube Volume and Area Calculator</Title>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Length</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  type="number"
                  ref={Length}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Select Unit</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Select>
                  <select
                    aria-label="unitSelector"
                    className="staticSelect"
                    ref={unitSelector}
                  >
                    <option value="m">m</option>
                    <option value="cm">cm</option>
                    <option value="mm">mm</option>
                    <option value="km">km</option>
                    <option value="dm">dm</option>
                  </select>
                </Select>
              </Control>
            </Field>
            <Button
              borderColor="#1da1f2"
              color="#1da1f2"
              hoverColor="white"
              hoverBorderColor="#1da1f2"
              hoverBackgroundColor="#1da1f2"
              onClick={calcVolume}
            >
              Calculate Volume
            </Button>
            <Button
              borderColor="#1da1f2"
              color="white"
              backgroundColor="#1da1f2"
              hoverColor="#1da1f2"
              hoverBorderColor="#1da1f2"
              onClick={calcArea}
            >
              Calculate area
            </Button>

            <p className="h5 mt-3">{result}</p>
          </Column>
          <Column>
            <FlexDiv maxWidth="250px" margin="auto">
              <SmallImg filename="cube.png" alt="cube calculator" />
            </FlexDiv>
          </Column>
        </Columns>
        <br />

        <h3>How To Calculate Cube Volume?</h3>
        <p>
          <strong>
            Volume = lenght<sup>3</sup> , <br />
            ex : Cube have lenght = 15m Volume = 15<sup>3</sup> = 3375m
            <sup>3</sup>{" "}
          </strong>
          .
        </p>

        <br />

        <h3>How To CalCulate Cube Area?</h3>
        <p>
          <strong>
            Cube Area = 6 * Lenght<sup>2</sup>,
            <br />
            ex : lenght = 14 , Cube Area = 6 * 14<sup>2</sup> = 1176m
            <sup>2</sup>.
          </strong>

          <strong>
            {" "}
            the surface area of a cube = Lenght * Lenght , or Lenght
            <sup>2</sup>.
          </strong>
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default CubeCalculatorVolumeArea
